import React, { useEffect, useState } from 'react'
import BrandTable from '../../Table/BrandTable';
import AddBrandModal from '../../Modal/Brand/AddBrandModal';
import axios from 'axios';

const Brand = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/brand')
                .then((e) => {
                    setBrands(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>{isModal ? <AddBrandModal closeModal={closeModal} /> :
            <div className='container'>
                <div>
                    <button onClick={() => setIsModal(true)} className='button'>Бренд нэмэх</button>
                </div>
                <BrandTable brands={brands} />
            </div>
        }</>
    )
}

export default Brand

import axios from 'axios';
import React, { useState } from 'react'

const EditBrandModal = ({ closeModal, id, brand }) => {
    const [name, setName] = useState(brand.name);
    const [file, setFile] = useState('');

    const createBrand = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file[0]);

        axios.put('https://erhem.templateapi.xyz/say/brand/' + id, formData)
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Бренд нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label htmlFor="">
                    Нэр:
                    <input onChange={(e) => setName(e.target.value)} value={name}
                        placeholder='Нэр' type="text" name="" id="" />
                </label>
                <label>
                    Зураг:
                    <input onChange={(e) => setFile(e.target.files)}
                        type="file" name="" id="" />
                </label>
                <img src={file ? URL.createObjectURL(file[0]) : ''} alt="" />
                <div className="two-line">
                    <button onClick={createBrand} style={{ color: 'green' }}>Save</button>
                    <button onClick={closeModal} style={{ color: 'red' }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default EditBrandModal

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import DeleteAskModal from '../Modal/Ask/DeleteAskModal';
import EditProductModal from '../Modal/Product/EditProductModal';

export default function ProductTable({ products }) {
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [product, setProduct] = useState({});
    const [id, setId] = useState('');

    const closeModal = () => {
        setIsDelete(false);
        setIsEdit(false);
    }

    const onDelete = () => {
        axios.delete('https://erhem.templateapi.xyz/say/product/id/' + id)
            .then((e) => window.location.reload(false))
            .catch((e) => console.log(e))
    }

    return (
        <>{isEdit && id && product ? <EditProductModal id={id} product={product} closeModal={closeModal} /> :
            isDelete && id ? <DeleteAskModal closeModal={closeModal} ondelete={onDelete} /> :
                <TableContainer sx={{ width: '80vw' }} component={Paper}>
                    <Table sx={{ width: '80vw' }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ background: '#FBD7D7' }}>
                                <TableCell>№</TableCell>
                                <TableCell>Нэр</TableCell>
                                <TableCell align="center">категори</TableCell>
                                <TableCell align="center">Үнэ</TableCell>
                                <TableCell align="center">Хуучин үнэ</TableCell>
                                <TableCell align="center">Хямдрал</TableCell>
                                <TableCell align="center">Бренд</TableCell>
                                <TableCell align="center">Үйлдэл</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row, index) => (
                                <TableRow
                                    className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.productName}
                                    </TableCell>
                                    <TableCell align="center">{row.category}</TableCell>
                                    <TableCell align="center">{row.price}</TableCell>
                                    <TableCell align="center">{row.oldPrice}</TableCell>
                                    <TableCell align="center">{row.discount}</TableCell>
                                    <TableCell align="center">{row.brand}</TableCell>
                                    <TableCell align="center">
                                        <MdDeleteOutline size={25} color='red' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsDelete(true)
                                            }} />
                                        <MdModeEditOutline size={25} color='blue' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsEdit(true)
                                                setProduct(row)
                                            }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }</>

    );
}

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import DeleteAskModal from '../Modal/Ask/DeleteAskModal';
import EditTypeModal from '../Modal/Type/EditTypeModal';

export default function TypeTable({ types }) {
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [type, setType] = useState({});
    const [id, setId] = useState('');

    const closeModal = () => {
        setIsDelete(false);
        setIsEdit(false);
    }

    const onDelete = () => {
        axios.delete('https://erhem.templateapi.xyz/say/type/' + id)
            .then((e) => window.location.reload(false))
            .catch((e) => console.log(e))
    }
    return (
        <>{isEdit && id && type ? <EditTypeModal closeModal={closeModal} type={type} /> :
            isDelete && id ? <DeleteAskModal closeModal={closeModal} ondelete={onDelete} /> :
                <TableContainer sx={{ width: '80vw' }} component={Paper}>
                    <Table sx={{ width: '80vw' }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ background: '#FBD7D7' }}>
                                <TableCell align='left'>№</TableCell>
                                <TableCell>Зураг</TableCell>
                                <TableCell align="center">Нэр</TableCell>
                                <TableCell align="center">Үйлдэл</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {types.map((row, index) => (
                                <TableRow
                                    className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {/* {row.image} */}
                                        <img src={'https://erhem.templateapi.xyz/say/uploads/' + row.image}
                                            style={{ width: '50px', height: '50px' }}
                                            alt="" />
                                    </TableCell>
                                    <TableCell align="center">{row.cat_name}</TableCell>
                                    <TableCell align="center">
                                        <MdDeleteOutline size={25} color='red' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsDelete(true)
                                            }} />
                                        <MdModeEditOutline size={25} color='blue' className='hover-icon'
                                            onClick={() => {
                                                setId(row._id);
                                                setIsEdit(true)
                                                setType(row)
                                            }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }</>

    );
}

import React, { useEffect, useState } from 'react'
import CategoryTable from '../../Table/CategoryTable'
import AddCategoryModal from '../../Modal/Category/AddCategoryModal';
import axios from 'axios';

const Category = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/type')
                .then((e) => {
                    setTypes(e.data.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://erhem.templateapi.xyz/say/categories')
                .then((e) => {
                    setIsLoading(false);
                    setCategories(e.data.data);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>{isModal ? <AddCategoryModal types={types} closeModal={closeModal} /> :
            <div className='container'>
                <div>
                    <button onClick={() => setIsModal(true)} className='button'>Ангилал нэмэх</button>
                </div>
                <CategoryTable categories={categories} types={types} />
            </div>
        }</>
    )
}

export default Category

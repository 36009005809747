import axios from 'axios';
import React, { useState } from 'react'

const EditTypeModal = ({ closeModal, type }) => {
    const [name, setName] = useState(type.cat_name);
    const [special, setSpecial] = useState(type.special);
    const [file, setFile] = useState('');

    const createType = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('cat_name', name);
        formData.append('special', special);
        formData.append('file', file[0]);

        axios.post('https://erhem.templateapi.xyz/say/type', formData)
            .then((e) => window.location.reload(false))
            .catch((e) => alert(e))
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Төрөл засах</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label htmlFor="">
                    Нэр:
                    <input onChange={(e) => setName(e.target.value)} value={name}
                        placeholder='Нэр' type="text" name="" id="" />
                </label>
                <label>
                    Зураг:
                    <input onChange={(e) => setFile(e.target.files)}
                        type="file" name="" id="" />
                </label>
                <label>
                    Онцгой:
                    <select value={special} onChange={(e) => setSpecial(e.target.value)}>
                        <option value="">Сонгох</option>
                        <option value="false">Онцгой биш</option>
                        <option value="true">Онцгой бараа</option>
                    </select>
                </label>
                <img src={file ? URL.createObjectURL(file[0]) : ''} alt="" />
                <div className="two-line">
                    <button onClick={createType} style={{ color: 'green' }}>Save</button>
                    <button onClick={closeModal} style={{ color: 'red' }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default EditTypeModal

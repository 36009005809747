import React, { useEffect, useState } from 'react'
import TypeTable from '../../Table/TypeTable'
import axios from 'axios';
import AddSliderModal from '../../Modal/Slider/AddSliderModal';
import SliderTable from '../../Table/SliderTable';

const Slider = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sliders, setSliders] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/sliders')
                .then((e) => {
                    setSliders(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }
    return (
        <>{isModal ? <AddSliderModal closeModal={closeModal} /> :
            <div className='container'>
                <div>
                    <button onClick={() => setIsModal(true)} className='button'>Нүүр зураг нэмэх</button>
                </div>
                <SliderTable sliders={sliders} />
            </div>
        }</>
    )
}

export default Slider

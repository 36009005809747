import React, { useEffect, useState } from 'react'
import OrderItemsTable from '../../Table/OrderItemsTable'
import axios from 'axios';

const OrderDetialModal = ({ closeModal, items }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/product')
                .then((e) => {
                    setProducts(e.data.data);
                    setIsLoading(false);
                })
        }
    }, [isLoading])
    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Захиалгын бараа</h2>
                    <h2 style={{ cursor: 'pointer' }}
                        onClick={closeModal} >X
                    </h2>
                </div>
                {/* {items.items.length} */}
                <OrderItemsTable products={products} items={items.items} />
            </div>
        </div>
    )
}

export default OrderDetialModal

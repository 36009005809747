import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IoMdAddCircleOutline } from "react-icons/io";
import OrderDetialModal from '../Modal/Order/OrderDetialModal';
import { FaPen, FaTrash } from "react-icons/fa";
import SelectUserOrderModal from '../Modal/Order/SelectUserOrderModal';
import axios from 'axios';

export default function OrderTable({ orders, types }) {
    const [isModal, setIsModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [order, setOrder] = useState({});

    const closeModal = () => {
        setIsModal(false);
        setIsEditModal(false)
    }

    const deleteOrder = ({ id }) => {
        // alert(id);
        if (window.confirm('Та устгах уу ?')) {
            axios.delete('https://erhem.templateapi.xyz/say/order/id/' + id)
                .then(() => window.location.reload(false))
        }
    }

    return (
        <> {isEditModal && order ? <SelectUserOrderModal closeModal={closeModal} order={order} />
            : isModal && order ? <OrderDetialModal items={order} closeModal={closeModal} /> :
                <TableContainer sx={{ width: '80vw' }} component={Paper}>
                    <Table sx={{ width: '80vw' }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ background: '#FBD7D7' }}>
                                <TableCell></TableCell>
                                <TableCell>Нэр</TableCell>
                                <TableCell align="center">Дүүрэг</TableCell>
                                <TableCell align="center">Хороолол, хотхон</TableCell>
                                <TableCell align="center">Орц, тоот</TableCell>
                                <TableCell align="center">Утасны дугаар</TableCell>
                                <TableCell align="center">Тайлбар</TableCell>
                                <TableCell align="center">Мессэж</TableCell>
                                <TableCell align="center">Төлөв</TableCell>
                                <TableCell align="center">Үйлдэл</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((row) => (
                                <TableRow
                                    className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">
                                        <IoMdAddCircleOutline onClick={() => {
                                            setIsModal(true);
                                            setOrder(row)
                                        }}
                                            size={25} color='green' cursor={'pointer'} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.state}</TableCell>
                                    <TableCell align="center">{row.steet}</TableCell>
                                    <TableCell align="center">{row.number}</TableCell>
                                    <TableCell align="center">{row.phone}</TableCell>
                                    <TableCell align="center">{row.description}</TableCell>
                                    <TableCell align="center">{row.message}</TableCell>
                                    <TableCell align="center">{
                                        row.status ? <p>Захиалга бэлэн болсон</p> : <p>Захиалга бэлэн болоогүй</p>
                                    }</TableCell>
                                    <TableCell align="center">
                                        <FaTrash color='red' cursor={'pointer'} size={20} onClick={() => deleteOrder({ id: row._id })} />
                                        <FaPen color='blue' cursor={'pointer'} size={20}
                                            onClick={() => {
                                                setIsEditModal(true);
                                                setOrder(row)
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }</>
    );
}

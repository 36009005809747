import React, { useEffect, useState } from 'react'
import ProductTable from '../../Table/ProductTable'
import AddProductModal from '../../Modal/Product/AddProductModal';
import axios from 'axios';

const Products = () => {
    const [isModal, setIsModal] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/product')
                .then((e) => {
                    setProducts(e.data.data);
                    setIsLoading(false);
                })
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>{isLoading ? <></> :
            isModal ? <AddProductModal closeModal={closeModal} /> :
                <div className='container'>
                    <div>
                        <button onClick={() => setIsModal(true)} className='button'>Бараа нэмэх</button>
                    </div>
                    <ProductTable products={products} />
                </div>
        }</>
    )
}

export default Products

import React, { useEffect, useState } from 'react'
import OrderTable from '../../Table/OrderTable'
import axios from 'axios';

const Order = () => {
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/order')
                .then((e) => {
                    setOrders(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const closeModal = () => {
        setIsModal(false);
    }

    return (
        <>
            <div className='container'>
                <div>
                    <button className='button'>Add Order</button>
                </div>
                <OrderTable orders={orders} />
            </div>
        </>
    )
}

export default Order

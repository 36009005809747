import axios from 'axios';
import React, { useState } from 'react'

const AddCategoryModal = ({ closeModal, types }) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState('');
    const [type, setType] = useState('');

    const createCategory = () => {

        const formData = new FormData();
        formData.append('cat_name', name);
        formData.append('file', file[0]);
        formData.append('type', type);

        axios.post('https://erhem.templateapi.xyz/say/categories', formData)
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Ангилал нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label htmlFor="">
                    Нэр:
                    <input onChange={(e) => setName(e.target.value)} value={name}
                        placeholder='Нэр' type="text" name="" id="" />
                </label>
                <label>
                    Зураг:
                    <input onChange={(e) => setFile(e.target.files)}
                        type="file" name="" id="" />
                </label>
                <label>
                    Төрөл:
                    <select onChange={(e) => setType(e.target.value)}>
                        <option value="">Сонгох</option>
                        {types.map((e) => (
                            <option value={e._id}>{e.cat_name}</option>
                        ))}
                    </select>
                </label>
                <img src={file ? URL.createObjectURL(file[0]) : ''} alt="" />
                <div className="two-line">
                    <button onClick={createCategory} style={{ color: 'green' }}>Save</button>
                    <button onClick={closeModal} style={{ color: 'red' }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AddCategoryModal
